import { gql } from "@apollo/client";

export const CREATE_NOTIFICATION_SETTING = gql`
mutation CreateNotificationSetting($input: CreateNotificationSettingInputDto!) {
  createNotificationSetting(input: $input) {
    message
  }
}`

export const UPDATE_NOTIFICATION_SETTING_BY_ID = gql`
mutation UpdateNotificationSetting($input: updateNotificationSettingInputDto!) {
  updateNotificationSetting(input: $input) {
    message
  }
}
`