import { NavItemType } from "types";

import siderBarItems from "./SidebarItems";

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  //   items: [dashboard, userManagement, contentManagement, settings],
  items: [siderBarItems],
};

export default menuItems;
