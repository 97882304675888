import { useMutation, useQuery } from "@apollo/client";

import {
  FORGOT_PASSWORD,
  LOGIN,
  REGISTER,
  RESET_PASSWORD,
  VERIFY_TOKEN,
  GET_ADMIN_LIST,
  GET_ADMIN as ADMIN,
  UPDATE_ADMIN as ADMIN_UPDATE,
  DELETE_ADMIN as ADMIN_DELETE,
  GET_USER_PROFILE,
  UPLOAD_PROFILE_IMAGE as IMAGE_UPLOAD,
  UPLOAD_MENU_IMAGE as IMG_UPLOAD,
  CHANGE_PASSWORD,
  CREATE_PAGE as CREATE_PG,
  UPDATE_PAGE as UPDATE_PG,
  DELETE_PAGE as DELETE_PG,
  LIST_PAGE as GET_LIST_PAGE,
  LIST_PAGES as GET_LIST_PAGES,
  CREATE_MENU as CREATE_MENUS,
  LIST_MENUS as GET_LIST_MENUS,
  DELETE_MENU as MENU_DELETE,
  UPDATE_MENU as MENU_UPDATE,
  UPDATE_MENU_STATUS as MENU_STATUS_UPDATE,
  LIST_MENU as GET_LIST_MENU,
  CODE_VERIFICATION,
  RESEND_OTP_CODE,
  VALIDATE_OTP,
} from "../grapqhl";

type GetAdminListDTO = {
  searchText?: string;
  orderBy?: string;
  order?: string;
  limit?: number;
  skip?: number;
};

type GetPagesDTO = {
  searchText?: string;
  orderBy?: string;
  order?: string;
  limit?: number;
  skip?: number;
  status?: string;
};

type GetMenusDTO = {
  searchText?: string;
  orderBy?: string;
  order?: string;
  limit?: number;
  skip?: number;
};

type GetListDTO = {
  searchText?: string;
  orderBy?: string;
  order?: string;
  limit?: number;
  skip?: number;
};

type GetTaxonsDTO = {
  searchText?: string;
  orderBy?: string;
  order?: string;
  limit?: number;
  skip?: number;
  taxonomyId: string | undefined;
};

type GetTaxonByTaxonomyIdDTO = {
  taxonomyId: string | undefined;
};

const useGQL = () => {
  const ADMIN_LOGIN = () => useMutation(LOGIN);
  const ADMIN_REGISTER = () => useMutation(REGISTER);
  const FORGOT_PASS = () => useMutation(FORGOT_PASSWORD);
  const RESET_PASS = () => useMutation(RESET_PASSWORD);
  const TOKEN_VERIFY = () => useMutation(VERIFY_TOKEN);
  const ADMIN_CHANGE_PASSWORD = () => useMutation(CHANGE_PASSWORD);
  const CODE_VERIFY = () => useMutation(CODE_VERIFICATION);
  const RESEND_CODE = () => useMutation(RESEND_OTP_CODE);

  /* admins */
  const GET_ALL_ADMINS = (input: GetAdminListDTO = {}) =>
    useQuery(GET_ADMIN_LIST, { variables: { input } });
  const GET_ADMIN = (id: String) => useQuery(ADMIN, { variables: { id } });
  const UPDATE_ADMIN = () => useMutation(ADMIN_UPDATE);
  const DELETE_ADMIN = () => useMutation(ADMIN_DELETE);
  const GET_ADMIN_PROFILE = () => useQuery(GET_USER_PROFILE);
  const UPLOAD_PROFILE_IMAGE = () => useMutation(IMAGE_UPLOAD);

  const CREATE_PAGE = () => useMutation(CREATE_PG);
  const UPDATE_PAGE = () => useMutation(UPDATE_PG);
  const DELETE_PAGE = () => useMutation(DELETE_PG);
  const LIST_PAGES = (input: GetPagesDTO = {}) =>
    useQuery(GET_LIST_PAGES, {
      variables: { input },
      notifyOnNetworkStatusChange: true,
    });
  const LIST_PAGE = (slug: String) =>
    useQuery(GET_LIST_PAGE, { variables: { slug } });

  const CREATE_MENU = () => useMutation(CREATE_MENUS);
  const LIST_MENUS = (input: GetMenusDTO = {}) =>
    useQuery(GET_LIST_MENUS, {
      variables: { input },
      notifyOnNetworkStatusChange: true,
    });
  const DELETE_MENU = () => useMutation(MENU_DELETE);
  const LIST_MENU = (id: String | undefined) =>
    useQuery(GET_LIST_MENU, { variables: { id }, fetchPolicy: "network-only" });
  const UPDATE_MENU = () => useMutation(MENU_UPDATE);
  const UPDATE_MENU_STATUS = () => useMutation(MENU_STATUS_UPDATE);
  const UPLOAD_MENU_IMAGE = () => useMutation(IMG_UPLOAD);

  const VALIDATE_AUTH_OTP = () => useMutation(VALIDATE_OTP);

  return {
    ADMIN_LOGIN,
    ADMIN_REGISTER,
    FORGOT_PASS,
    RESET_PASS,
    TOKEN_VERIFY,
    GET_ALL_ADMINS,
    GET_ADMIN,
    UPDATE_ADMIN,
    DELETE_ADMIN,
    GET_ADMIN_PROFILE,
    ADMIN_CHANGE_PASSWORD,
    CREATE_PAGE,
    UPDATE_PAGE,
    DELETE_PAGE,
    LIST_PAGES,
    LIST_PAGE,
    CREATE_MENU,
    LIST_MENUS,
    DELETE_MENU,
    LIST_MENU,
    UPDATE_MENU,
    UPDATE_MENU_STATUS,
    UPLOAD_MENU_IMAGE,
    CODE_VERIFY,
    RESEND_CODE,
    UPLOAD_PROFILE_IMAGE,
    VALIDATE_AUTH_OTP,
  };
};

export default useGQL;
