// project imports
import { Theme } from "@mui/material/styles";

export default function componentStyleOverrides(
  theme: Theme,
  borderRadius: number,
  outlinedFilled: boolean
) {
  const mode = theme.palette.mode;
  const bgColor =
    mode === "dark" ? theme.palette.dark[800] : theme.palette.grey[50];
  const menuSelectedBack =
    mode === "dark"
      ? theme.palette.secondary.main + 15
      : theme.palette.secondary.light;
  const menuSelected =
    mode === "dark"
      ? theme.palette.secondary.main
      : theme.palette.secondary.dark;

  return {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          lineHeight: "23px",
          borderRadius: "36px",
          padding: "16px",
          fontSize: "16px",
          [theme.breakpoints.down("sm")]: {
            fontSize: 12,
            padding: "8px",
          },
          "&.applyButton": {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.background.paper,
            ":hover": {
              backgroundColor: theme.palette.primary.dark,
            },
          },
          "&.cancelButton": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.primary.dark,
            border: `1px solid ${theme.palette.warning.main}`,
          },
          "&.h-40": {
            height: 40,
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        container: {
          "&.radioButtonWrapper": {
            ".MuiGrid-item": {
              position: "relative",

              paddingTop: "24px",
              [theme.breakpoints.up("sm")]: {
                paddingLeft: "24px",
                paddingTop: 0,
              },
              "&:first-child": {
                paddingLeft: 0,
                paddingTop: 0,
                "&:before": {
                  display: "none",
                },
              },
              "&:before": {
                content: '""',
                position: "absolute",
                left: 0,
                top: 0,
                // bottom: 16,
                width: "100%",
                height: "1px",
                backgroundColor: theme.palette.grey[300],
                [theme.breakpoints.up("sm")]: {
                  bottom: 16,
                  width: 1,
                  height: "auto",
                },
              },
            },
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
            {
              webkitAppearance: "none",
              appearance: "none",
              margin: 0,
            },
        },
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          "&.radioRow": {
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            [theme.breakpoints.up("sm")]: {
              flexDirection: "row",
            },
          },
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: "none",

          ".bordered-box": {
            border: "1px solid #e3f2fd",
            borderRadius: "12px",
            // padding: "10px",
          },
        },
        rounded: {
          borderRadius: `12px`,
          "&.p-20": {
            padding: "20px",
          },
          "&.p-12": {
            padding: 12,
          },
          ".blue-bg": {
            backgroundColor: theme.palette.primary.main,
            padding: "8px",
            borderRadius: "12px",
          },
          ".blue-border": {
            border: `1px solid ${theme.palette.primary.main}`,
            padding: "8px",
            borderRadius: "12px",
          },
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {},
        ol: {
          background: "white",
          borderRadius: "12px",
          padding: "8px 16px",
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: theme.palette.text.dark,
          padding: "24px",
        },
        title: {
          fontSize: "1.125rem",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: "center",
        },
        outlined: {
          border: "1px dashed",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
          paddingTop: "10px",
          paddingBottom: "10px",
          "&.Mui-selected": {
            color: menuSelected,
            backgroundColor: menuSelectedBack,
            "&:hover": {
              backgroundColor: menuSelectedBack,
            },
            "& .MuiListItemIcon-root": {
              color: menuSelected,
            },
          },
          "&:hover": {
            backgroundColor: menuSelectedBack,
            color: menuSelected,
            "& .MuiListItemIcon-root": {
              color: menuSelected,
            },
          },
        },
        MuiCard: {
          styleOverrides: {
            root: {
              borderRadius: 12,
            },
          },
        },
        MuiCardHeader: {
          styleOverrides: {
            root: {
              color: theme.palette.text.dark,
              padding: "24px",
            },
            title: {
              fontSize: "1.125rem",
            },
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            color: theme.palette.text.primary,
            minWidth: "36px",
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          primary: {
            color: theme.palette.text.dark,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            color: theme.palette.text.dark,
            "&::placeholder": {
              color: theme.palette.text.secondary,
              fontSize: "0.875rem",
            },
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          root: {
            "&.Mui-disabled": {
              color:
                mode === "dark"
                  ? theme.palette.text.primary + 50
                  : theme.palette.grey[300],
            },
          },
          mark: {
            backgroundColor: theme.palette.background.paper,
            width: "4px",
          },
          valueLabel: {
            color:
              mode === "dark"
                ? theme.palette.primary.main
                : theme.palette.primary.light,
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            "& .MuiAutocomplete-tag": {
              background:
                mode === "dark"
                  ? theme.palette.text.primary + 20
                  : theme.palette.secondary.light,
              borderRadius: 4,
              color: theme.palette.text.dark,
              ".MuiChip-deleteIcon": {
                color:
                  mode === "dark"
                    ? theme.palette.text.primary + 80
                    : theme.palette.secondary[200],
              },
            },
          },
          popper: {
            borderRadius: `${borderRadius}px`,
            boxShadow:
              "0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)",
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: theme.palette.divider,
            opacity: mode === "dark" ? 0.2 : 1,
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "&:focus": {
              backgroundColor: "transparent",
            },
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            color:
              mode === "dark"
                ? theme.palette.dark.main
                : theme.palette.primary.dark,
            background:
              mode === "dark"
                ? theme.palette.text.primary
                : theme.palette.primary[200],
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            "&.MuiChip-deletable .MuiChip-deleteIcon": {
              color: "inherit",
            },
          },
        },
      },
      MuiTimelineContent: {
        styleOverrides: {
          root: {
            color: theme.palette.text.dark,
            fontSize: "16px",
          },
        },
      },
      MuiTreeItem: {
        styleOverrides: {
          label: {
            marginTop: 14,
            marginBottom: 14,
          },
        },
      },
      MuiTimelineDot: {
        styleOverrides: {
          root: {
            boxShadow: "none",
          },
        },
      },
      MuiInternalDateTimePickerTabs: {
        styleOverrides: {
          tabs: {
            backgroundColor:
              mode === "dark"
                ? theme.palette.dark[900]
                : theme.palette.primary.light,
            "& .MuiTabs-flexContainer": {
              borderColor:
                mode === "dark"
                  ? theme.palette.text.primary + 20
                  : theme.palette.primary[200],
            },
            "& .MuiTab-root": {
              color:
                mode === "dark"
                  ? theme.palette.text.secondary
                  : theme.palette.grey[900],
            },
            "& .MuiTabs-indicator": {
              backgroundColor: theme.palette.primary.dark,
            },
            "& .Mui-selected": {
              color: theme.palette.primary.dark,
            },
          },
        },
      },

      MuiDialog: {
        styleOverrides: {
          paper: {
            padding: "12px 0 12px 0",
          },
        },
      },

      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            color: theme.palette.background.paper,
            background: theme.palette.text.primary,
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontSize: "1.25rem",
          },
        },
      },
      MuiPagination: {
        styleOverrides: {
          root: {
            ".MuiPaginationItem-root": {
              backgroundColor: theme.palette.background.paper,

              "&.Mui-selected": {
                backgroundColor: theme.palette.error.main,
                color: theme.palette.background.paper,
              },
              "&.MuiPaginationItem-previousNext": {
                borderRadius: "50%",
                width: 24,
                height: 24,
                display: "flex",
                minWidth: "auto",
                justifyContent: "center",
                color: theme.palette.warning.main,
              },
            },
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
              {
                webkitAppearance: "none",
                appearance: "none",
                margin: 0,
              },
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          ".Mui-selected": {
            backgroundColor: theme.palette.error.main,
            color: "#FFF !important",
            borderRadius: "12px",
          },
          button: {
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "20px",
            color: "#303637",
          },
          "&.MuiTabs-vertical": {
            ".Mui-selected": {
              backgroundColor: theme.palette.primary.main,
              color: `${theme.palette.grey[900]} !important`,
              borderRadius: 0,
              borderRight: `2px solid ${theme.palette.primary.dark}`,
            },
            button: {
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "20px",
              color: theme.palette.grey[900],
              textAlign: "left !important",
            },
          },
          "&.filter-tabs": {
            ".Mui-selected": {
              backgroundColor: "transparent",
              "> div": {
                borderBottom: `2px solid ${theme.palette.error.main}`,
              },
            },
          },
        },
        indicator: {
          display: "none",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.Mui-checked": {
            color: theme.palette.primary.dark,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.grey[900],
          fontSize: 14,
          fontWeight: 400,
          lineHeight: "20px",
          marginBottom: 8,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: 44,
          background: "transparent",
          borderRadius: `${borderRadius}px`,
          "&.orange-rounded-border": {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: `${theme.palette.orange.main} !important`,
            },
            ".MuiSelect-select": {
              fontWeight: 400,
            },
            svg: {
              marginRight: "7px",
            },
          },
          "&:hover $notchedOutline": {
            borderColor: theme.palette.primary.light,
          },
          "&.MuiInputBase-multiline": {
            padding: 1,
          },
        },

        input: {
          fontWeight: 500,
          background: "transparent",
          padding: "15.5px 14px",
          borderRadius: `${borderRadius}px`,
          "&.MuiInputBase-inputSizeSmall": {
            padding: "10px 14px",
            "&.MuiInputBase-inputAdornedStart": {
              paddingLeft: 0,
            },
          },
        },

        inputAdornedStart: {
          paddingLeft: 4,
        },
        notchedOutline: {
          borderRadius: `36px`,
          backgroundColor: "transparent",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          background: "none",

          ".MuiAccordionSummary-root": {
            background: theme.palette.primary.main,
            height: 48,

            "&.Mui-expanded": {
              minHeight: 48,
            },
          },
          ".MuiAccordionDetails-root": {
            padding: 0,
            ".MuiCheckbox-root": {
              left: "-10px",
            },
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          border: "none !important",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "#ECECEC",
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          ".MuiPaginationItem-previousNext": {
            minWidth: 30,
            width: 30,
            height: 30,
            borderRadius: "50%",
            backgroundColor: theme.palette.background.default,
          },
          ".Mui-selected": {
            backgroundColor: `${theme.palette.error.main} !important`,
            color: `${theme.palette.background.paper} !important`,
            ":hover": {
              backgroundColor: theme.palette.error.main,
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: 10,
          "&.MuiTableCell-head": {
            fontSize: "12px",
            fontWeight: 500,
            color: theme.palette.grey[500],
          },
          "&.MuiTableCell-body": {
            fontSize: "14px",
            fontWeight: 400,
            color: "#2e2e2e",
          },
        },
      },
    },
  };
}
