const ActiveNotificationIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M10.4286 19.9244L13.9911 18.4123C14.1244 19.2925 13.6531 20.185 12.7958 20.5489C11.9385 20.9128 10.9692 20.6318 10.4286 19.9244ZM15.8613 2.09392C17.947 3.23347 19.5128 4.69659 20.543 6.48101C21.5732 8.26543 22.0575 10.353 22.0015 12.7291C21.9917 13.1432 21.6481 13.4709 21.234 13.4612C20.8199 13.4514 20.4921 13.1078 20.5019 12.6937C20.5516 10.5824 20.1304 8.76635 19.244 7.23101C18.3575 5.69566 16.9954 4.42285 15.1421 3.41027C14.7786 3.21167 14.6449 2.756 14.8435 2.3925C15.0421 2.029 15.4978 1.89532 15.8613 2.09392ZM6.71144 6.51547C9.57319 5.24134 12.9156 6.4461 14.3109 9.26099L14.4092 9.47041L15.6966 12.3623L17.3949 13.832C17.4876 13.9123 17.5679 14.0057 17.661 14.1568L17.7197 14.2711C17.9991 14.9024 17.7138 15.6407 17.0825 15.9201L6.75588 20.4902C6.59655 20.5607 6.42424 20.5972 6.25 20.5972C5.55964 20.5972 5 20.0375 5 19.3475L4.99892 17.0046L3.75654 14.2132C2.44304 11.2631 3.76572 7.82699 6.71144 6.51547ZM15.6237 5.69457C16.5907 6.22184 17.3657 6.9892 17.94 7.98387C18.5142 8.97855 18.7913 10.0334 18.7644 11.1345C18.7543 11.5486 18.4105 11.8761 17.9964 11.866C17.5823 11.8559 17.2548 11.512 17.2649 11.0979C17.2849 10.2763 17.0792 9.49294 16.6409 8.73387C16.2027 7.97481 15.6272 7.40494 14.9056 7.01151C14.542 6.81321 14.4079 6.35765 14.6062 5.99399C14.8045 5.63033 15.2601 5.49627 15.6237 5.69457Z"
        fill="#053C5E"
      />
    </svg>
  );
};

export default ActiveNotificationIcon;
