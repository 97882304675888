// third-party

// assets
import { IconDashboard, IconDeviceAnalytics } from "@tabler/icons";

// type
import { NavItemType } from "types";
import ReportIcon from "components/icons/ReportIcon";
import NotificationIcon from "components/icons/NotificationIcon";
import PatientsIcon from "components/Icons/PatientsIcon";

const icons = {
  IconDashboard: IconDashboard,
  IconDeviceAnalytics: IconDeviceAnalytics,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const siderBarItems: NavItemType = {
  id: "dashboard",
  icon: icons.IconDashboard,
  type: "group",
  children: [
    {
      id: "patients",
      type: "item",
      url: "/patients",
      icon: PatientsIcon,
      breadcrumbs: false,
    },
    {
      id: "reports",
      type: "item",
      url: "/reports",
      icon: ReportIcon,
      breadcrumbs: false,
    },
    {
      id: "notifications",
      type: "item",
      url: "/notifications",
      icon: NotificationIcon,
      breadcrumbs: false,
    },
  ],
};

export default siderBarItems;
