import { lazy } from "react";
// project imports
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import PageList from "views/pageManagement";

// User managemenet routing

const AdminProfile = Loadable(lazy(() => import("views/profile")));
const AdminAccountProfile = Loadable(
  lazy(() => import("views/profile/user-profile"))
);
const AdminChangePassword = Loadable(
  lazy(() => import("views/profile/change-password"))
);

// Mock component
const MockComponent = Loadable(lazy(() => import("views/mock")));

const Patients = Loadable(lazy(() => import("views/patients/index")));

const Reports = Loadable(lazy(() => import("views/reports/index")));

const Notification = Loadable(
  lazy(() => import("views/notification-settings/index"))
);

const ViewPatient = Loadable(
  lazy(() => import("views/patients/details/PatientDetails"))
);

const ViewAllReport = Loadable(
  lazy(() => import("views/reports/view/AllReports"))
);

// Error routing
// const ErrorPage = Loadable(lazy(() => import('views/pages/maintenance/error')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: (
    // <AuthGuard>
    <MainLayout />
    // </AuthGuard>
  ),
  children: [
    {
      path: "page-management/:slug",
      element: <PageList />,
    },
    {
      path: "admin/profile",
      element: <AdminProfile />,
    },
    {
      path: "admin/account-profile",
      element: <AdminAccountProfile />,
    },
    {
      path: "admin/change-password",
      element: <AdminChangePassword />,
    },

    {
      path: "notifications",
      element: <Notification />,
    },
    {
      path: "mock",
      element: <MockComponent />,
    },
    {
      path: "patients",
      element: <Patients />,
    },
    {
      path: "reports",
      element: <Reports />,
    },
    {
      path: "notifications",
      element: <Notification />,
    },
    {
      path: "/patients/view/:id",
      element: <ViewPatient />,
    },
    {
      path: "/reports/view",
      element: <ViewAllReport />,
    },
  ],
};

export default MainRoutes;
