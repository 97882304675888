// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, Button, Divider, Typography } from "@mui/material";

// project imports
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/system";

// ==============================|| FOOTER ||============================== //

const Footer = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const handleTC = () => {
    navigate("/page-management/Terms_and_Conditions");
  };

  const handlePP = () => {
    navigate("/page-management/Privacy_Policy");
  };

  return (
    <Box
      sx={{
        padding: "10px 15px 10px 72px",
        [theme.breakpoints.down("sm")]: {
          padding: "10px",
        },
        [theme.breakpoints.up("sm")]: {
          padding: "15px",
        },
        [theme.breakpoints.up("md")]: {
          padding: "10px 15px 10px 75px",
        },
      }}
    >
      <Divider
        sx={{
          borderColor: "rgba(43, 41, 41, 0.3)",
          my: { xs: 2, sm: 3, md: 4 },
        }}
      />
      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent={{ xs: "center", sm: "space-between" }}
        alignItems="center"
        rowGap={1.5}
        mb={3}
      >
        {/* Left-aligned text */}
        <Typography
          fontSize={12}
          fontWeight={400}
          letterSpacing="1px"
          lineHeight="15px"
          color="#2b2929"
        >
          © 2023 Glucose Management
        </Typography>

        {/* Right-aligned buttons */}
        <Stack direction="row" columnGap={1.5}>
          <Typography
            fontSize={12}
            fontWeight={400}
            letterSpacing="1px"
            lineHeight="15px"
            color="#2b2929"
            sx={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={handleTC}
          >
            Terms and Conditions
          </Typography>
          <Typography
            fontSize={12}
            fontWeight={400}
            letterSpacing="1px"
            lineHeight="15px"
            color="#2b2929"
            sx={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={handlePP}
          >
            Privacy Policy
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Footer;
