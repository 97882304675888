// routing
import Routes from "routes";

// project imports
import Locales from "ui-component/Locales";
import NavigationScroll from "layout/NavigationScroll";
import Snackbar from "ui-component/extended/Snackbar";
import ThemeCustomization from "themes";

// auth provider
// import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
// import { JWTProvider as AuthProvider } from 'contexts/JWTContext';

// ==============================|| APP ||============================== //

const App = () => (
  <ThemeCustomization>
    {/* RTL layout */}
    <Locales>
      <NavigationScroll>
        {/* <AuthProvider> */}
        <>
          <Routes />
          <Snackbar />
        </>
        {/* </AuthProvider> */}
      </NavigationScroll>
    </Locales>
  </ThemeCustomization>
);

export default App;
