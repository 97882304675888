import { gql } from "@apollo/client";

export const GET_LOCATIONS = gql`
  query GetAllLocations($searchText: String!) {
    getAllLocations(searchText: $searchText) {
      Locations {
        _id
        displayAddress
      }
    }
  }
`;
