import { gql } from '@apollo/client';

export const LIST_PAGES = gql`
    query ($input: GetPagesDTO!) {
        listPages(input: $input) {
            message
            pages {
                _id
                title
                slug
                status
                content
                createdBy
                updatedAt
                createdAt
            }
            pagination {
                total
                hasNextPage
            }
        }
    }
`;

export const LIST_PAGE = gql`
    query ListPageTemplate($slug: String!) {
        listPageTemplate(slug: $slug) {
        page {
            _id
            content
            createdAt
            status
            title
            updatedAt
        }
        message
        }
    }
`;
