function ReportIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="53"
      height="48"
      fill="none"
      viewBox="0 0 53 48"
    >
      <path
        fill="#053C5E"
        d="M22.25 16.25A.75.75 0 0123 17v8h8a.75.75 0 01.743.648l.007.102c0 4.97-4.03 8.5-9 8.5a9 9 0 01-9-9c0-4.97 3.53-9 8.5-9zm-.75 1.537l-.209.024c-3.69.47-6.041 3.622-6.041 7.439a7.5 7.5 0 007.5 7.5c3.817 0 6.968-2.352 7.44-6.041l.022-.209H22.25a.75.75 0 01-.743-.648l-.007-.102v-7.963zm3.75-4.037a9 9 0 019 9 .75.75 0 01-.75.75h-8.25a.75.75 0 01-.75-.75V14.5a.75.75 0 01.75-.75zm.75 1.537V22h6.712l-.023-.209a7.504 7.504 0 00-6.48-6.48L26 15.287z"
      ></path>
    </svg>
  );
}

export default ReportIcon;
