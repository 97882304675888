function NotificationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="53"
      height="48"
      fill="none"
      viewBox="0 0 53 48"
    >
      <path
        fill="#053C5E"
        d="M22.429 31.924l3.562-1.512a1.999 1.999 0 01-3.562 1.512zm5.432-17.83c2.086 1.139 3.652 2.602 4.682 4.386 1.03 1.785 1.514 3.872 1.459 6.249a.75.75 0 01-1.5-.036c.05-2.111-.372-3.927-1.258-5.463-.886-1.535-2.249-2.808-4.102-3.82a.75.75 0 01.72-1.317zm-9.15 4.421a5.824 5.824 0 017.6 2.745l.098.21 1.288 2.892 1.698 1.47c.093.08.173.173.266.324l.059.115a1.25 1.25 0 01-.637 1.649l-10.327 4.57A1.25 1.25 0 0117 31.347l-.001-2.343-1.242-2.791a5.822 5.822 0 012.954-7.698zm-1.584 7.088l1.372 3.082v2.278l9.616-4.256-1.653-1.43-1.417-3.183-.085-.181a4.321 4.321 0 00-5.639-2.028 4.322 4.322 0 00-2.194 5.718zm10.497-7.909c.967.527 1.742 1.295 2.316 2.29.574.994.851 2.049.824 3.15a.75.75 0 11-1.5-.037c.02-.821-.185-1.605-.623-2.364-.438-.759-1.014-1.329-1.735-1.722a.75.75 0 01.718-1.317z"
      ></path>
    </svg>
  );
}

export default NotificationIcon;
