import { gql } from "@apollo/client";

export const CREATE_LOCATION_GROUP = gql`
  mutation CreateGroupOfLocation(
    $createGroupOfLocationInput2: CreateGroupLocationInputDTO!
  ) {
    createGroupOfLocation(input: $createGroupOfLocationInput2) {
      message
      groupId
    }
  }
`;
