/* eslint no-underscore-dangle: 0 */
/* eslint no-nested-ternary: 0 */

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { resetForm } from "store/slices/form";

import useGQL from "hooks/useGQL";
import { Card, CardContent, Divider, Stack } from "@mui/material";

const PageList = () => {
  const { slug } = useParams<{ slug?: string }>();
  const rootDispatch = useDispatch();
  const { LIST_PAGE } = useGQL();
  const { data } = LIST_PAGE(slug || "");

  useEffect(() => {
    sessionStorage.clear();
    rootDispatch(resetForm());
  }, [rootDispatch]);

  return (
    <>
      <Stack rowGap={2}>
        <Card>
          <CardContent>
            <div style={{ fontWeight: "bold" }}>
              {data?.listPageTemplate?.page?.title}
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <div
              dangerouslySetInnerHTML={{
                __html: data?.listPageTemplate?.page?.content,
              }}
            ></div>
          </CardContent>
        </Card>
        <Divider style={{ borderColor: "#D3D3D3" }} />
      </Stack>
    </>
  );
};

export default PageList;
