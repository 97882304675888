import { gql } from "@apollo/client";

export const GET_NOTIFICATION_BY_ID = gql`
query GetNotificationSetting {
  getNotificationSetting {
    data {
      _id
      doctorId
      emailNotification
      enrollments {
        dataTypeId
        isNotify
      }
      locations
      smsNotification
      webappNotification
    }
    message
  }
}
`