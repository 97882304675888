import { createRoot } from "react-dom/client";

// third party
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

// project imports
import App from "App";
import { BASE_PATH } from "config";
import { persister, store } from "store";
import * as serviceWorker from "serviceWorker";
import reportWebVitals from "reportWebVitals";
import { ConfigProvider } from "contexts/ConfigContext";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  Observable,
  FetchResult,
  ApolloLink,
  GraphQLRequest,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

// style + assets
import "assets/scss/style.scss";
import "assets/style.css";
import "react-nestable/dist/styles/index.css";
import { GraphQLError } from "graphql";
import { REFRESH_TOKEN } from "grapqhl";
import { RefreshTokenResponse } from "types/auth";

/* GRAPHQL CLIENT INIT  */
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URI,
});
function isRefreshRequest(operation: GraphQLRequest) {
  return operation.operationName === "RefreshToken";
}

// Returns accesstoken if opoeration is not a refresh token request
function returnTokenDependingOnOperation(operation: GraphQLRequest) {
  if (isRefreshRequest(operation)) {
    return localStorage.getItem("refreshToken") || "";
  }
  return localStorage.getItem("accessToken") || "";
}

const authLink = setContext((operation, { headers }) => {
  // get the authentication token from local storage if it exists

  let token = returnTokenDependingOnOperation(operation);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.extensions.code) {
          case "UNAUTHENTICATED":
            // ignore 401 error for a refresh request
            if (operation.operationName === "RefreshToken") {
              localStorage.clear();
              document.location.href = "/";
              return;
            }
            const observable = new Observable<FetchResult<Record<string, any>>>(
              (observer) => {
                // used an annonymous function for using an async function
                (async () => {
                  try {
                    const accessToken = await requestRefreshToken();
                    if (!accessToken) {
                      throw new GraphQLError("Empty AccessToken");
                    }
                    // Retry the failed request
                    const subscriber = {
                      next: observer.next.bind(observer),
                      error: observer.error.bind(observer),
                      complete: observer.complete.bind(observer),
                    };

                    forward(operation).subscribe(subscriber);
                  } catch (error: any) {
                    observer.error(error);
                  }
                })();
              }
            );

            return observable;
        }
      }
    }

    if (networkError) console.log(`[Network error]: ${networkError}`);
  }
);

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({ addTypename: false }),
});

// Request a refresh token to then stores and returns the accessToken.
const requestRefreshToken = async () => {
  try {
    let refreshToken = localStorage.getItem("refreshToken");
    if (!refreshToken) {
      return "";
    }
    const refreshResolverResponse = await client.mutate<{
      refresh: RefreshTokenResponse;
    }>({
      mutation: REFRESH_TOKEN,
      variables: { refreshToken },
    });

    const accessToken = refreshResolverResponse.data?.refresh?.accessToken;
    refreshToken = String(
      refreshResolverResponse.data?.refresh?.refreshToken || ""
    );
    localStorage.setItem("accessToken", accessToken || "");
    localStorage.setItem("refreshToken", refreshToken || "");
    return accessToken;
  } catch (err) {
    throw err;
  }
};
// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persister}>
      <ConfigProvider>
        <BrowserRouter basename={BASE_PATH}>
          <ApolloProvider client={client}>
            <App />
          </ApolloProvider>
        </BrowserRouter>
      </ConfigProvider>
    </PersistGate>
  </Provider>
  //   </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
