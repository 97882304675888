// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;
const LAYOUT_CONST = {
  VERTICAL_LAYOUT: "vertical",
  HORIZONTAL_LAYOUT: "horizontal",
  DEFAULT_DRAWER: "default",
  MINI_DRAWER: "mini-drawer",
};

export default LAYOUT_CONST;

export enum SnackBarAlertType {
  ERROR = "error",
  SUCCESS = "success",
}

export const DEFAULT_ROWS_PER_PAGE = 10;

export const DEFAULT_TOTAL_ITEMS = 0;

export const DEFAULT_ITEMS = "items";

export const PATIENTS_KEYS_ARRAY = [
  "age",
  "weight",
  "glucose.lowest",
  "glucose.highest",
  "diabetes",
  "glucoseTestingLast24Hrs",
  "hypoGlycaemiaRiskScore",
  "hbA1c",
];

export enum PatientKeys {
  GLUCOSE_HIGHEST = "glucose.highest",
  GLUCOSE_LOWEST = "glucose.lowest",
  LOCATION = "location.displayAddress",
  UNIT = "unit.name",
}

export enum HighAndLow {
  HIGHEST = "highest",
  LOWEST = "lowest",
}

export enum PatientkeyColumns {
  LOCATION = "location",
  UNIT = "unit",
}

export enum FetchPolicy {
  NETWORK_ONLY = "network-only",
}

export enum RadioValues {
  ANY = "ANY",
  TRUE = "TRUE",
  FALSE = "FALSE",
}

export enum RadioKeys {
  ANY = "Any",
  YES = "YES",
  NO = "NO",
}

export enum SexTypeValues {
  ANY = "ANY",
  MALE = "MALE",
  FEMALE = "FEMALE",
}

export enum SexTypeLabels {
  ANY = "Any",
  MALE = "Male",
  FEMALE = "Female",
}

export const MAX_UNIT_GROUPS = 3;

export const MAX_LOCATION_GROUPS = 4;

export const ALL = "All";

export const DEFAULT_LOCATION_UNIT = "1";

export const NONE = "None";

export const initialValues = {
  age: {
    from: "",
    to: "",
  },
  highestglucoseRange: {
    from: "",
    to: "",
  },
  defaultFilter: false,
  glucoseTestingLast24Hrs: RadioValues.ANY,
  hbA1c: {
    from: "",
    to: "",
  },
  hypoGlycaemiaRiskScore: RadioValues.ANY,
  sex: SexTypeValues.ANY,
  lowestglucoseRange: {
    from: "",
    to: "",
  },
  weight: {
    from: "",
    to: "",
  },
  hasDiabetes: RadioValues.ANY,
  location: [DEFAULT_LOCATION_UNIT],
  unit: [DEFAULT_LOCATION_UNIT],
  name: "",
};

export enum SaveFilterEnum {
  SAVE = "save",
  FILTER = "filter",
}

export const API_ERROR = "Something went wrong";

export enum SidebarItemsUrl {
  PATIENTS = "patients",
  NOTIFICATION = "notifications",
  REPORTS = "reports",
}

export enum Groups {
  LOCATION = "location",
  UNIT = "unit",
}

export const DIALOG_MAX_WIDTH = 450;

export enum PatientGlucoseLabels {
  BIOCHEMISTRY = "Biochemistry",
  MEDICATIONS = "Medications",
  GLUCOSE_MANAGEMENT = "Glucose Management",
  NON_DIABETES_TREATMENT = "Non-Diabetes Treatment",
  MEDICATION_PREESCRIPTION_DATA = "Medication Prescription Data",
  MEDICATION_ADMINISTRATION_DATA = "Medication Administration Data",
}

export const DEFAULT_HOUR = 0;
export const MAX_HOUR = 24;
export const MINIMUM_INTEGER_COUNT = 2;
export const DEFAULT_PATIENTS_ROW_PER_PAGE = 30;
